'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Gatsby = require("gatsby");
var Grid$Paulshen = require("../components/Grid.bs.js");
var Page$Paulshen = require("../ui/Page.bs.js");
var Text$Paulshen = require("../ui/Text.bs.js");
var Fonts$Paulshen = require("../constants/Fonts.bs.js");
var Colors$Paulshen = require("../constants/Colors.bs.js");
var MediaQuery$Paulshen = require("../constants/MediaQuery.bs.js");
var ExternalPhoto$Paulshen = require("../components/ExternalPhoto.bs.js");
var WindowDimensions$Paulshen = require("../components/WindowDimensions.bs.js");

var root = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* :: */[
        Css.maxWidth(Css.px(896)),
        /* :: */[
          Css.marginLeft(Css.auto),
          /* :: */[
            Css.marginRight(Css.auto),
            /* [] */0
          ]
        ]
      ]
    ]);

var pane = Css.style(/* :: */[
      Css.width(Css.pct(50)),
      /* :: */[
        Css.paddingRight(Css.px(64)),
        /* :: */[
          Css.boxSizing(Css.borderBox),
          /* :: */[
            Css.marginBottom(Css.px(32)),
            /* :: */[
              MediaQuery$Paulshen._840(/* :: */[
                    Css.paddingRight(Css.zero),
                    /* :: */[
                      Css.width(Css.auto),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var name = Css.style(/* :: */[
      Css.fontSize(Css.px(36)),
      /* :: */[
        Css.fontWeight(Css.normal),
        /* :: */[
          Css.marginBottom(Css.px(32)),
          /* :: */[
            MediaQuery$Paulshen._840(/* :: */[
                  Css.fontSize(Css.px(24)),
                  /* :: */[
                    Css.marginBottom(Css.px(16)),
                    /* [] */0
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var introBlurb = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary300),
      /* :: */[
        Css.maxWidth(Css.px(480)),
        /* :: */[
          Css.fontSize(Css.px(14)),
          /* :: */[
            Css.lineHeight(Css.em(1.5)),
            /* :: */[
              MediaQuery$Paulshen._840(/* :: */[
                    Css.fontSize(Css.px(14)),
                    /* :: */[
                      Css.marginBottom(Css.px(32)),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var links = Css.style(/* :: */[
      Css.marginTop(Css.px(32)),
      /* :: */[
        Css.marginBottom(Css.px(32)),
        /* :: */[
          Css.maxWidth(Css.px(256)),
          /* :: */[
            MediaQuery$Paulshen._840(/* :: */[
                  Css.marginTop(Css.px(32)),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var linkRow = Css.style(/* [] */0);

var link = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary300),
      /* :: */[
        Css.fontFamily(Fonts$Paulshen.mono),
        /* :: */[
          Css.lineHeight(Css.em(1.5)),
          /* :: */[
            Css.textDecoration(Css.none),
            /* :: */[
              Css.flexGrow(1),
              /* :: */[
                Css.hover(/* :: */[
                      Css.textDecoration(Css.underline),
                      /* :: */[
                        Css.color(Colors$Paulshen.primary100),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var drawing = Css.style(/* [] */0);

var drawingLabel = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* [] */0
    ]);

var item = Css.style(/* :: */[
      Css.borderTop(Css.px(1), Css.solid, Colors$Paulshen.primary475),
      /* :: */[
        Css.padding2(Css.px(16), Css.zero),
        /* [] */0
      ]
    ]);

var articlesTitle = Css.style(/* :: */[
      Css.marginBottom(Css.px(16)),
      /* [] */0
    ]);

var articleLink = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary200),
      /* :: */[
        Css.display(Css.inlineBlock),
        /* :: */[
          Css.fontSize(Css.px(18)),
          /* :: */[
            Css.fontWeight(Css.medium),
            /* :: */[
              Css.textDecoration(Css.none),
              /* :: */[
                Css.marginBottom(Css.px(4)),
                /* :: */[
                  Css.hover(/* :: */[
                        Css.textDecoration(Css.underline),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var articleMeta = Css.style(/* :: */[
      Css.fontSize(Css.px(12)),
      /* :: */[
        Css.lineHeight(Css.em(1.5)),
        /* [] */0
      ]
    ]);

var articleBlurb = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary300),
      /* :: */[
        Css.marginRight(Css.px(4)),
        /* [] */0
      ]
    ]);

var articleDate = Css.style(/* :: */[
      Css.color(Colors$Paulshen.primary400),
      /* :: */[
        Css.fontWeight(Css.light),
        /* :: */[
          Css.whiteSpace(Css.nowrap),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  root: root,
  pane: pane,
  name: name,
  introBlurb: introBlurb,
  links: links,
  linkRow: linkRow,
  link: link,
  drawing: drawing,
  drawingLabel: drawingLabel,
  item: item,
  articlesTitle: articlesTitle,
  articleLink: articleLink,
  articleMeta: articleMeta,
  articleBlurb: articleBlurb,
  articleDate: articleDate
};

function HomePage$Links(Props) {
  var data = Props.data;
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  className: links
                }, React.createElement("div", {
                      className: linkRow
                    }, React.createElement(Gatsby.Link, {
                          to: "/drawings",
                          children: "/drawings",
                          className: link
                        })), React.createElement("div", {
                      className: linkRow
                    }, React.createElement(Gatsby.Link, {
                          to: "/photos",
                          children: "/photos",
                          className: link
                        })), React.createElement("div", {
                      className: linkRow
                    }, React.createElement(Gatsby.Link, {
                          to: "/about",
                          children: "/about",
                          className: link
                        }))), React.createElement(ExternalPhoto$Paulshen.make, {
                  data: data.file.externalImages,
                  url: /* Local */Block.__(1, ["draw/35999786_217229322446458_3470015180652412928_n.jpg"]),
                  className: drawing
                }), React.createElement(Text$Paulshen.Tiny.make, {
                  children: "I scribble lines of random things",
                  className: drawingLabel
                }));
}

var Links = {
  make: HomePage$Links
};

function HomePage(Props) {
  var data = Props.data;
  var windowWidth = React.useContext(WindowDimensions$Paulshen.context);
  var match = windowWidth > 840;
  var match$1 = windowWidth <= 840;
  return React.createElement(Page$Paulshen.make, {
              children: React.createElement("div", {
                    className: root
                  }, React.createElement(Grid$Paulshen.Root.make, {
                        children: null
                      }, React.createElement(Grid$Paulshen.Cell.make, {
                            span: 5,
                            children: null,
                            fullWidthOnMobile: true
                          }, React.createElement("h1", {
                                className: name
                              }, "paul shen"), React.createElement("div", {
                                className: introBlurb
                              }, React.createElement("p", undefined, "Product engineering, programming languages, React, and other random things that pop in my head.")), match ? React.createElement(HomePage$Links, {
                                  data: data
                                }) : null), React.createElement(Grid$Paulshen.Cell.make, {
                            span: 1
                          }), React.createElement(Grid$Paulshen.Cell.make, {
                            span: 6,
                            children: null,
                            fullWidthOnMobile: true
                          }, React.createElement(Text$Paulshen.MonoHeader.make, {
                                children: "Posts",
                                tag: /* H2 */16106,
                                className: articlesTitle
                              }), data.allMdx.nodes.map((function (mdxNode) {
                                  var match = mdxNode.frontmatter.blurb;
                                  return React.createElement("div", {
                                              key: mdxNode.frontmatter.slug,
                                              className: item
                                            }, React.createElement(Gatsby.Link, {
                                                  to: "/posts" + mdxNode.frontmatter.slug,
                                                  children: mdxNode.frontmatter.title,
                                                  className: articleLink
                                                }), React.createElement("div", {
                                                  className: articleMeta
                                                }, (match == null) ? null : React.createElement("span", {
                                                        className: articleBlurb
                                                      }, match), React.createElement("span", {
                                                      className: articleDate
                                                    }, mdxNode.frontmatter.date)));
                                })), match$1 ? React.createElement(HomePage$Links, {
                                  data: data
                                }) : null))),
              header: false
            });
}

var frontmatter = {
  externalImages: /* array */["draw/35999786_217229322446458_3470015180652412928_n.jpg"]
};


const {graphql} = require('gatsby');
exports.query = graphql`
  query HomePage($filePath: String) {
    allMdx(
      filter: {frontmatter: {published: {eq: true}}},
      sort: {order: DESC, fields: frontmatter___date}
    ) {
      nodes {
        id
        frontmatter {
          slug
          title
          blurb
          date(formatString: "MMM YYYY")
        }
      }
    }
    file(absolutePath: { eq: $filePath }) {
      externalImages {
        relativePath
        childImageSharp {
          fluid(maxWidth: 384, quality: 80) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  }
`;

;

var make = HomePage;

var slug = "/";

var $$default = HomePage;

exports.Styles = Styles;
exports.Links = Links;
exports.make = make;
exports.slug = slug;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
exports.frontmatter = frontmatter;
/* root Not a pure module */
